import React from 'react';
import '../../App.css';
import './Contribute.css'
import { useTranslation } from 'react-i18next';

export default function Contribution() {
  const { t } = useTranslation(); // Use the hook to get the translation function

  return ( 
    <div className='contribute-container'>
        <h1 className='products'>{t('contribute')}</h1>
        <p>{t('contributetext')}</p>
        <div class="divider-line"></div>
        <p>panoramap.hungary@gmail.com</p>

        
        <div class="divider-line"></div>
        <p>{t('paypal_text')}</p>
        <p></p>
        <div className='paypal-donate'>
          <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="hosted_button_id" value="YG37HD8DMAXEN" />
            <input type="image" className='paypal-donate-button' src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_HU/i/scr/pixel.gif" width="1" height="1" />
          </form>
        </div>
  </div>

)  
}
