import React from 'react';
import '../App.css';
import './AboutOwner.css';
import { useTranslation } from 'react-i18next';

function AboutOwner() {
  const { t } = useTranslation(); // Use the hook to get the translation function
  
  return (
    <div className="aboutowner-container">
      {/* Left Section - Picture */}
      <div className="left-section">
        <img
          src="/images/hiking.jpg" // Replace with the path to your image
          alt="Owner"
          className="owner-image"
        />
      </div>

      {/* Right Section - Description */}
      <div className="right-section">
        <div className="right-content">
          <h2 className="header">{t('about')}</h2>
          <p className="description">{t('aboutme')}</p> 
          
          <p className="description">{t('aboutdrone')}</p> 

          <p className="description">{t('abouttheproject')}</p>
        </div>
      </div>
    </div>
  );
}

export default AboutOwner;
