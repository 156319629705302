import React from 'react';
import '../../App.css';
import './Contribute.css'
import { useTranslation } from 'react-i18next';

export default function Thanks() {
  const { t } = useTranslation(); // Use the hook to get the translation function

  return ( 
    <div className='contribute-container'>
        <h1 className='products'>{t('thankyou')}</h1>
        <p>{t('appreciated')}</p>
  </div>

)  
}
